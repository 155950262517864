<template>
  <div v-if="$route.name != 'unauthorized'">
    <v-bottom-navigation background-color="background"
                         grow
                         fixed
                         height="60"
                         v-resize="onResize">
      <v-btn v-for="item in visible"
             :key="item.title"
             :to="item.link"
             text
             @click="toggleActive()"
             active-class="toActive">
        <span class="mobileNavigationTitle">{{ translations[item.title] }}</span>
        <v-icon class="mb-1">{{ item.icon }}</v-icon>
      </v-btn>
      <v-btn text
             v-if="hidden"
             @click="toggleActive(true)"
             :class="getWildcard[1]">
        <span class="mobileNavigationTitle">{{ translations[getWildcard[0]] }}</span>
        <v-icon class="mb-1">mdi-dots-horizontal</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation v-if="hidden"
                         background-color="background"
                         class="elevation-0 hiddenWrapper"
                         fixed
                         grow
                         height="60"
                         :class="{showHidden: active}">
      <v-btn v-for="hide in hidden"
             :key="hide.title"
             :to="hide.link"
             text
             @click="toggleActive()"
             active-class="toActive">
        <span class="mobileNavigationTitle">{{ translations[hide.title] }}</span>
        <v-icon class="mb-1">{{ hide.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  chunk,
  concat,
  filter,
  find,
  floor,
  fill,
} from 'lodash';
import { authorizedNavigation } from '@/utility';

export default {
  name: 'navbarMobile',
  data() {
    return {
      active: true,
      hidden: [],
      visible: [],
    };
  },
  methods: {
    onResize() {
      [this.visible, this.hidden] = chunk(filter(authorizedNavigation(), ['mobile', true]), floor(window.innerWidth / 100));
      // adjust the length of popup navbar
      if (this.hidden && this.hidden.length) {
        this.hidden = concat(this.hidden,
          fill(Array((this.visible.length + 1) - this.hidden.length), {}));
      }
    },
    toggleActive(toggler) {
      this.active = !toggler || !this.active;
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'translations',
    ]),
    getWildcard() {
      const path = find(this.hidden, ['link', this.$route.matched[0].path]);
      if (path) {
        return [path.title, 'activeMore'];
      }
      return ['more'];
    },
  },
};
</script>

<style lang="scss" scoped>
  .mobileNavigationTitle {
    font-size: 11px !important;
    font-weight: 400 !important;
    letter-spacing: 0.4px;
  }
  .v-btn::before {
    background-color: transparent !important;
  }
  .hiddenWrapper {
    margin-bottom: 60px;
    z-index: 2 !important;
  }
  .toActive,
  .activeMore {
    color: var(--v-primary-base) !important;
  }
  .theme--light.v-bottom-navigation .activeMore:not(.v-btn--active),
  .theme--dark.v-bottom-navigation .activeMore:not(.v-btn--active) {
    color: var(--v-primary-base) !important;
  }
  .showHidden {
    transform: translateY(200%) !important;
  }
</style>
