import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reportsGeneralTabWrapper pt-4"},[_c(VTabs,{attrs:{"color":"text","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"primary"}}),_vm._l((_vm.items),function(item){return _c(VTab,{key:item,attrs:{"ripple":false}},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c(VDivider),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"transition":false,"reverse-transition":false}},_vm._l((_vm.filteredInfo),function(info,index){return _c('div',{key:index,staticClass:"pl-3 mt-2"},[_c(VRow,[_c('p',{staticClass:"subtitle-1 pt-6 mb-0 subtitle--text"},[_vm._v(_vm._s(info.title))])]),_c(VRow,{staticClass:"my-4"},_vm._l((info.data),function(item,index){return _c(VCol,{key:index,staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('div',{staticClass:"reportsGameDataWrapper"},[_c('p',{staticClass:"label body-2 mt-2"},[_vm._v(_vm._s(item.label)+" "),_c(VTooltip,{attrs:{"transition":"slide-x-transition","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({staticClass:"pl-2 mt-n1",attrs:{"dark":"","small":"","color":"disabled"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.info)}})])],1),_c('p',{staticClass:"value caption subtitle--text"},[_vm._v(" "+_vm._s(_vm.selectedReportsGame[item.value] || '-')+" ")])])])}),1)],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }