import {
  add,
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  formatISO,
  getDay,
  getMonth,
  getWeek,
  isBefore,
  isEqual,
  parseISO,
  setDay,
  setMonth,
  setWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns';
import { map } from 'lodash';

// formaters
// when changing date format watch for substring in promotions
export const isoToDate = (date) => format(parseISO(date), 'dd.MM.yyy. HH:mm:ss');
export const isoToDateNoFormat = (date) => format(parseISO(date), 'yyyy-MM-dd HH:mm:ss');
export const isoToShortDate = (date) => format(parseISO(date), 'dd.MM.yyyy.');
export const isoToTagName = (date) => format(parseISO(date), 'yyyy-MM-dd_HH-mm-ss');
export const dateToIsoStartOfDay = (date) => new Date(startOfDay(parseISO(date))).toISOString();
export const dateToIsoEndOfDay = (date) => new Date(endOfDay(parseISO(date))).toISOString();
export const isoToDateByTimeZone = (date) => format(parseISO(new Date(date).toISOString()), 'dd.MM.yyy. HH:mm:ss');

// predefined date range (filter) calculations
export const getPredefinedRange = (date, select) => {
  let range = [];
  switch (select.type) {
    case 'day':
      range = [
        startOfDay(setDay(date, getDay(date) - select.index)),
        endOfDay(setDay(date, getDay(date) - select.index)),
      ];
      break;
    case 'week':
      range = [
        startOfWeek(setWeek(date, getWeek(date) - select.index), { weekStartsOn: 1 }),
        endOfWeek(setWeek(date, getWeek(date) - select.index), { weekStartsOn: 1 }),
      ];
      break;
    case 'month':
      range = [
        startOfMonth(setMonth(date, getMonth(date) - select.index)),
        endOfMonth(setMonth(date, getMonth(date) - select.index)),
      ];
      break;
    case 'year':
      range = [
        startOfYear(date),
        endOfYear(date),
      ];
      break;
    default:
      break;
  }
  return map(range, (item) => (formatISO(item, { representation: 'date' })));
};
export const getDifferenceInDays = (date1, date2) => differenceInDays(
  parseISO(date1),
  parseISO(date2),
);
export const getRangeFromNow = (date, index) => {
  const range = [];
  function getDate(value) {
    range.push(formatISO(value, { representation: 'date' }));
  }
  getDate(startOfDay(setDay(date, getDay(date) - index)));
  getDate(date);
  return range;
};
export const addTimeToIsoDate = (date, time) => add(parseISO(date), time).toISOString();
export const subtractTimeFromIsoDate = (date, time) => sub(parseISO(date), time).toISOString();

// comparators
export const isBeforeNow = (date) => isBefore(parseISO(date), new Date());
export const isBeforeDate = (date1, date2) => isBefore(parseISO(date1), parseISO(date2));
export const isBeforeFullDate = (date1, date2) => isBefore(date1, date2);
export const isEqualDate = (date1, date2) => isEqual(parseISO(date1), parseISO(date2));

// QP date claculation
export const setDateFromTo = (date, offset) => {
  if (date.length) {
    const first = new Date(date[0]).valueOf();
    const second = new Date(date[1]).valueOf();
    if (offset) {
      const tzoffset = new Date(date[0]).getTimezoneOffset();
      if (first < second) {
        return [date[0], date[1], tzoffset];
      }
      return [date[1], date[0], tzoffset];
    }
    if (first < second) {
      return [dateToIsoStartOfDay(date[0]), dateToIsoEndOfDay(date[1])];
    }
    return [dateToIsoStartOfDay(date[1]), dateToIsoEndOfDay(date[0])];
  }
  return [];
};
