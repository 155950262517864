import {
  filter,
  includes,
  some,
} from 'lodash';
import store from '../store';

export default function authorizedNavigation() {
  if (store.getters.isBoostAdmin) {
    return filter(store.getters.navigation, (item) => some(item.roles, (role) => role === store.getters.userRole || role === 'boost_admin'));
  }
  return filter(store.getters.navigation, (item) => includes(item.roles,
    store.getters.userRole));
}
