import {
  filter,
  has,
  isEqual,
} from 'lodash';
import dataApi from '../api/data';
import types from './mutationTypes';

export default {
  async loadData({ dispatch }, payload) {
    if (!has(payload, 'loading')) {
      dispatch('setLoading', true);
    }
    const [response] = await dispatch('apiHandler', { action: 'loadData', payload });
    return response;
  },
  async updateData({ dispatch }, payload) {
    const [response] = await dispatch('apiHandler', { action: 'updateData', payload });
    return response;
  },
  async putData({ dispatch }, payload) {
    const [response] = await dispatch('apiHandler', { action: 'putData', payload });
    return response;
  },
  async createData({ dispatch }, payload) {
    const [response] = await dispatch('apiHandler', { action: 'createData', payload });
    return response;
  },
  async deleteData({ dispatch }, payload) {
    const [response] = await dispatch('apiHandler', { action: 'deleteData', payload });
    return response;
  },
  async apiHandler({ commit, dispatch }, data) {
    try {
      if (data.payload.boost) {
        commit(types.SET_BOOST_API_REQUEST, true);
      } else {
        commit(types.SET_BOOST_API_REQUEST, false);
      }
      const controller = new AbortController();
      commit(types.ADD_CANCEL_CONTROLLER, controller);
      const response = await dataApi[data.action](data.payload, controller.signal);
      if (data.payload.name) {
        commit(types[`SET_${data.payload.name.toUpperCase()}`], response);
      }
      if (data.payload.notification) {
        dispatch('setSuccessLabel', data.payload);
      }
      dispatch('setLoading', false);
      return [response, null];
    } catch (error) {
      if (isEqual(error.message, 'canceled')) {
        dispatch('setLoading', true);
      } else {
        dispatch('setErrorLabel', { data: data.payload, error });
        dispatch('setLoading', false);
      }
      return [null, error];
    }
  },
  setLoading({ commit }, payload) {
    commit(types.SET_LOADING, payload);
  },
  async getBootstrap({ commit, dispatch }) {
    const response = await dispatch('loadData', { path: 'backoffice/bootstrap', name: 'bootstrap' });
    await commit(types.SET_COMPANIES, filter(response.user.tenants, 'active'));
    await commit(types.SET_PARTNERS, filter(response.user.partners, 'active'));
    return response;
  },
  async getBoostBootstrap({ dispatch, commit }) {
    const response = await dispatch('loadData', { path: 'backoffice/bootstrap', name: 'boost_bootstrap', boost: true });
    if (response?.tenants) {
      await commit(types.SET_BOOST_COMPANIES, response.tenants);
    }
  },
  async setSelectedCompany({ commit }, payload) {
    await commit(types.SET_SELECTED_COMPANY, payload);
  },
  cancelPendingRequests({ commit, state }) {
    state.cancelControllers.forEach((request) => {
      request.abort();
    });
    commit(types.CLEAR_CANCEL_CONTROLLERS);
  },
  setSelectedPartner({ commit }, payload) {
    commit(types.SET_SELECTED_PARTNER, payload);
  },
  setDetailsModalConfig({ commit }, payload) {
    commit(types.SET_DETAILS_MODAL_CONFIG, payload);
  },
  toggleDetailsModal({ commit }, payload) {
    commit(types.TOGGLE_DETAILS_MODAL, payload);
  },
  setSelectedGame({ commit }, payload) {
    commit(types.SET_SELECTED_GAME, payload);
  },
  setSelectedRound({ commit }, payload) {
    commit(types.SET_SELECTED_ROUND, payload);
  },
  setSelectedAssetGame({ commit }, payload) {
    commit(types.SET_SELECTED_ASSET_GAME, payload);
  },
  setSelectedPromotion({ commit }, payload) {
    commit(types.SET_SELECTED_PROMOTION, payload);
  },
  setSelectedReportsGame({ commit }, payload) {
    commit(types.SET_SELECTED_REPORTS_GAME, payload);
  },
  setMobileDetection({ commit }, payload) {
    commit(types.SET_MOBILE_DETECTION, payload);
  },
  setToolbarTitle({ commit }, payload) {
    commit(types.SET_TOOLBAR_TITLE, payload);
  },
  setPusherMessage({ commit }, payload) {
    commit(types.SET_PUSHER_MESSAGE, payload);
  },
  setSnackbarNotification({ commit }, payload) {
    commit(types.SET_SNACKBAR_NOTIFICATION, payload);
  },
  setCurrencyValidation({ commit }, payload) {
    commit(types.SET_CURRENCY_VALIDATION, payload);
  },
  setUnifiedCurrency({ commit }, payload) {
    commit(types.SET_UNIFIED_CURRENCY, payload);
  },
  setExcludeInternal({ commit }, payload) {
    commit(types.SET_EXCLUDE_INTERNAL, payload);
  },
  setGameAssetsDetails({ commit }, payload) {
    commit(types.SET_GAME_ASSETS_DETAILS, payload);
  },
  clearGameAssetsDetails({ commit }) {
    commit(types.CLEAR_GAME_ASSETS_DETAILS, { data: [], pagination: {} });
  },
  toggleUploadAssetsDialog({ commit }, payload) {
    commit(types.TOGGLE_UPLOAD_ASSETS_DIALOG, payload);
  },
  // success and error label for API handling
  // payload.notification is required for success, error has default message
  setSuccessLabel({ commit, getters }, payload) {
    commit(types.SET_SNACKBAR_NOTIFICATION, {
      label: getters.translations[payload.notification],
      text: true,
      color: payload.color ? '' : 'success',
    });
  },
  setErrorLabel({ dispatch, commit, getters }, payload) {
    const notification = {
      label: getters.translations.errorLabel,
      text: true,
      color: 'error',
    };
    if (isEqual(payload.error.response?.data?.code, 'INVALID_PROMOTION_TENANTGAME_SETTING')) {
      notification.label = getters.translations[payload.data.notificationError];
      notification.promotionError = true;
      notification.promotionErrorList = payload.error.response.data;
    }
    if (payload.data.boost && isEqual(payload.error.response?.data?.message, 'VALIDATION_FAILED')) {
      notification.label = payload.error.response.data.details[0].message;
    }
    if (isEqual(payload.data.name, 'boost_bootstrap')) {
      notification.label = getters.translations.boostUnavailable;
      dispatch('setUnavailableBoost');
    }
    if (isEqual(payload.error.response?.data?.message, 'TOURNAMENT_COOLDOWN_ERROR')) {
      notification.label = getters.translations.tournamentCooldownError;
    }
    commit(types.SET_SNACKBAR_NOTIFICATION, notification);
  },
  setPromotionErrorList({ commit }, payload) {
    commit(types.SET_PROMOTION_ERROR_LIST, payload);
  },
  setAcceptHeader({ commit }, payload) {
    commit(types.SET_ACCEPT_HEADER, payload);
  },
  setImportFile({ commit }, payload) {
    commit(types.SET_IMPORT_FILE, payload);
    return payload;
  },
  finishGlobalLoading({ getters }) {
    const loadingElement = document.getElementById('loading');
    const isOnlyBoostUser = getters.userRole === 'boost_admin';
    // In case there is only boost tab active and boost bootstrap is not available
    // keep loading active
    if (loadingElement && !(isOnlyBoostUser && getters.isBoostUnavailable)) {
      loadingElement.classList.add('isDone');
      setTimeout(() => {
        loadingElement.remove();
      }, 1000);
    }
  },
  keycloakLogout({ state }) {
    state.keycloak.logout().then(() => {
      state.keycloak = null;
    });
  },
  keycloakRefreshToken({ state, dispatch }) {
    const lifespan = Math.round(state.keycloak.tokenParsed.exp + state.keycloak.timeSkew
      - new Date().getTime() / 1000) * 1000;
    setInterval(() => {
      state.keycloak.updateToken(lifespan).then(() => {
      }).catch(() => {
        dispatch.keycloakLogout();
      });
    }, lifespan);
  },
  setPromotionDetails({ commit }, payload) {
    commit(types.SET_PROMOTION_DETAILS, payload);
  },
  clearTournamentDetails({ commit }) {
    commit(types.SET_TOURNAMENT_DETAILS, {});
  },
  setUnavailableBoost({ commit }) {
    commit(types.SET_UNAVAILABLE_BOOST);
  },
};
