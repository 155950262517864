<template>
  <div class="ignoreGlobal">
    <p class="subtitle--text mb-3 ignoreGlobal">
      <LocalizedLabel>selectionLabel</LocalizedLabel>
    </p>
    <v-row v-for="(bet, index) in mappedGameDetails.bets"
           :key="index">
      <v-col class="p-0 m-0">
        <v-divider class="mt-2 mb-3"></v-divider>
        <p class="value subtitle--text my-0">
          {{ bet.name }}
        </p>
        <div class="d-flex flex-row flex-wrap my-2">
          <div class="numberContainer"
               :class="getNumberColor(number)"
               v-for="number in bet.value"
               :key="number">
            {{ number }}
          </div>
        </div>
        <div class="d-flex">
          <div class="betInfo">
            <p class="subtitle--text mb-2">
              <LocalizedLabel>stake</LocalizedLabel>
            </p>
            <p class="value mb-0">{{ bet.betAmount }}</p>
          </div>
          <div v-if="bet.isWon"
               class="betInfo">
            <p class="subtitle--text mb-2">
              <LocalizedLabel>winLabel</LocalizedLabel>
            </p>
            <p class="value mb-0">{{ bet.winAmount }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="p-0 m-0"
             cols="12">
        <v-divider class="mt-2 mb-3"></v-divider>
        <p class="subtitle--text mb-3 ignoreGlobal">
          <LocalizedLabel>roundsResultsLabel</LocalizedLabel>
        </p>
        <div class="numberContainer roundResult mt-2"
             :class="getNumberColor(mappedGameDetails.result)">
          {{ mappedGameDetails.result }}
        </div>
      </v-col>
    </v-row>
    <v-row v-if="mappedGameDetails.powerNumbers.length">
      <v-col class="p-0 m-0">
        <v-divider class="mt-2 mb-3"></v-divider>
        <p class="value subtitle--text my-0">
          <LocalizedLabel>powerNumbersLabel</LocalizedLabel>
        </p>
        <div class="d-flex flex-row flex-wrap my-2">
          <div class="numberContainer"
               :class="getNumberColor(powerNumber.number)"
               v-for="powerNumber in mappedGameDetails.powerNumbers"
               :key="powerNumber.number">
            <p class="powerNumberValue">
              {{ powerNumber.number }}
            </p>
            <div class="powerNumberOdds">
              {{ powerNumber.odd }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  capitalize,
  map,
  sortBy,
  toNumber,
} from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'rouletteRoundPreview',
  props: ['details'],
  data() {
    return {
      redNumbers: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
      blackNumbers: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
      zeroNumbers: [0, '00'],
      powerNumbers: [],
    };
  },
  computed: {
    ...mapGetters(['translations']),
    mappedGameDetails() {
      const bets = map(this.details.bets, (bet) => ({
        ...bet,
        name: this.translations[bet.name] || capitalize(bet.name),
        betAmount: this.formatAmount(bet.betAmount),
        winAmount: this.formatAmount(bet.winAmount),
        value: map(bet.value, (number) => this.formatNumber(number)),
      }));

      const powerNumbers = map(this.details.powerNumbers, (number, index) => {
        if (number instanceof Object) return number;
        const powerNumber = {
          number: this.formatNumber(number),
          odd: this.details.powerNumberOdds[index],
        };
        return powerNumber;
      });

      return {
        ...this.details,
        bets: sortBy(bets, (bet) => -bet.value.length),
        result: this.formatNumber(this.details.result),
        powerNumbers,
      };
    },
  },
  methods: {
    formatAmount(value) {
      return toNumber(value)?.toFixed(2);
    },
    formatNumber(number) {
      return number === -1 ? '00' : number;
    },
    getNumberColor(number) {
      if (this.redNumbers.includes(number)) return 'red';
      if (this.blackNumbers.includes(number)) return 'black';
      if (this.zeroNumbers.includes(number)) return 'green';
      return false;
    },
  },
};
</script>

<style scoped lang='scss'>
p:not(.ignoreGlobal) {
  font-size: 14px;
}

div:not(.ignoreGlobal) {
  padding-top: 0;
  padding-bottom: 0;
}

.d-flex {
  gap: 5px;
}

.betInfo {
  display: flex;
  flex-direction: column;
  min-width: 80px;
}

.value {
  font-weight: 500;
}

.red {
  background-color: #ac403c !important;
}

.black {
  background-color: #2c2c2c !important;
}

.green {
  background-color: #20822a !important;
}

.numberContainer {
  width: 45px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 2px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 1px;

  &.roundResult {
    width: 100%;
  }

  .powerNumberValue {
    margin: 0;
    line-height: 18px;
  }

  .powerNumberOdds {
    width: 100%;
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2c2c2c;
    background: #c6b06c;
  }
}
</style>
