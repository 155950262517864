export default {
  SET_COMPANIES: 'SET_COMPANIES',
  SET_PARTNERS: 'SET_PARTNERS',
  SET_GAMES: 'SET_GAMES',
  SET_SELECTED_COMPANY: 'SET_SELECTED_COMPANY',
  SET_SELECTED_PARTNER: 'SET_SELECTED_PARTNER',
  SET_DETAILS_MODAL_CONFIG: 'SET_DETAILS_MODAL_CONFIG',
  TOGGLE_DETAILS_MODAL: 'TOGGLE_DETAILS_MODAL',
  SET_COMPANY_GAMES: 'SET_COMPANY_GAMES',
  SET_COMPANY_GAME_ROUNDS: 'SET_COMPANY_GAME_ROUNDS',
  SET_SELECTED_GAME: 'SET_SELECTED_GAME',
  SET_SELECTED_ROUND: 'SET_SELECTED_ROUND',
  SET_COMPANY_GAME_DETAILS: 'SET_COMPANY_GAME_DETAILS',
  SET_PLAYERS: 'SET_PLAYERS',
  SET_TAGS: 'SET_TAGS',
  SET_PLAYER_TAGS: 'SET_PLAYER_TAGS',
  SET_PLAYER_TAG_DETAILS: 'SET_PLAYER_TAG_DETAILS',
  SET_ASSETS: 'SET_ASSETS',
  SET_SELECTED_ASSET_GAME: 'SET_SELECTED_ASSET_GAME',
  SET_BOOTSTRAP: 'SET_BOOTSTRAP',
  SET_REPORTS_GAMES: 'SET_REPORTS_GAMES',
  SET_SELECTED_REPORTS_GAME: 'SET_SELECTED_REPORTS_GAME',
  SET_SELECTED_PROMOTION: 'SET_SELECTED_PROMOTION',
  SET_PROMOTIONS: 'SET_PROMOTIONS',
  SET_PROMOTION_PLAYERS: 'SET_PROMOTION_PLAYERS',
  SET_PROMOTION_DETAILS: 'SET_PROMOTION_DETAILS',
  SET_REPORTS_PLAYERS: 'SET_REPORTS_PLAYERS',
  SET_PARTNERS_ACCESS_KEYS: 'SET_PARTNERS_ACCESS_KEYS',
  SET_PUSHER_MESSAGE: 'SET_PUSHER_MESSAGE',
  SET_ACCEPT_HEADER: 'SET_ACCEPT_HEADER',
  SET_SNACKBAR_NOTIFICATION: 'SET_SNACKBAR_NOTIFICATION',
  SET_IMPORT_FILE: 'SET_IMPORT_FILE',
  SET_MOBILE_DETECTION: 'SET_MOBILE_DETECTION',
  SET_TOOLBAR_TITLE: 'SET_TOOLBAR_TITLE',
  CLEAR_CANCEL_CONTROLLERS: 'CLEAR_CANCEL_CONTROLLERS',
  ADD_CANCEL_CONTROLLER: 'ADD_CANCEL_CONTROLLER',
  SET_LOADING: 'SET_LOADING',
  SET_CURRENCY_VALIDATION: 'SET_CURRENCY_VALIDATION',
  SET_PROMOTION_ERROR_LIST: 'SET_PROMOTION_ERROR_LIST',
  SET_UNIFIED_CURRENCY: 'SET_UNIFIED_CURRENCY',
  SET_GAME_ASSETS_DETAILS: 'SET_GAME_ASSETS_DETAILS',
  CLEAR_GAME_ASSETS_DETAILS: 'CLEAR_GAME_ASSETS_DETAILS',
  TOGGLE_UPLOAD_ASSETS_DIALOG: 'TOGGLE_UPLOAD_ASSETS_DIALOG',
  SET_BOOST_API_REQUEST: 'SET_BOOST_API_REQUEST',
  SET_TOURNAMENT_DETAILS: 'SET_TOURNAMENT_DETAILS',
  SET_BOOST_BOOTSTRAP: 'SET_BOOST_BOOTSTRAP',
  SET_BOOST_COMPANIES: 'SET_BOOST_COMPANIES',
  SET_EXCLUDE_INTERNAL: 'SET_EXCLUDE_INTERNAL',
  SET_UNAVAILABLE_BOOST: 'SET_UNAVAILABLE_BOOST',
};
