export default {
  install: function install(Vue) {
    Vue.component('LocalizedLabel', {
      name: 'LocalizedLabel',
      functional: true,
      props: [
        'translation',
        'class',
      ],
      render(createElement, context) {
        const slots = context.slots();
        const key = slots.default ? slots.default[0].text : context.props.translation;

        return createElement(
          'span',
          { staticClass: context.props.class },
          context.parent.$store.getters.translation(key),
        );
      },
    });
  },
};
