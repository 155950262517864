<template>
  <div>
    <v-app-bar height="60"
               fixed
               elevation="0"
               color="background">
      <v-btn icon
             plain
             class="text--text"
             v-if="toolbarTitle.active"
             @click="getLocation()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="title text--text px-0 font-weight-light">
        {{toolbarTitle.label || 'Backoffice'}}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <SettingsMenu v-if="!toolbarTitle.active" />

    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SettingsMenu from '@/components/SettingsMenu';

export default {
  name: 'appBarMobile',
  components: {
    SettingsMenu,
  },
  data() {
    return {

    };
  },
  methods: {
    ...mapActions([
      'setToolbarTitle',
    ]),
    getLocation() {
      this.$router.replace({ path: this.toolbarTitle.path });
      this.setToolbarTitle({ active: false });
    },
  },
  computed: {
    ...mapGetters([
      'toolbarTitle',
    ]),
  },
};
</script>
