import {
  forEach,
  clone,
  pick,
  isArray,
} from 'lodash';
import { setDateFromTo } from './formatDate';

export default function queryParams(search, value, tzoffset) {
  // generic filter handler
  const filtersList = [
    'bonusType',
    'channel',
    'currency',
    'gameType',
    'partnerId',
    'promotionType',
    'rtp',
    'status',
    'tags',
    'tenantGameId',
    'tenantId',
  ];
  const queryList = [];
  const clonedValue = clone(value);
  // tenantId is for requests that are not required to have company id
  // all other requests have company id in their URL

  // pick by control service QP
  const selectedFilters = pick(clonedValue, filtersList);
  forEach(selectedFilters, (filter, key) => {
    if (filter && filter.length) {
      if (isArray(filter)) {
        queryList.push(`${key}=${filter.join(',')}`);
      } else {
        queryList.push(`${key}=${filter}`);
      }
    }
  });
  // search filter
  if (search) queryList.push(`search=${encodeURIComponent(search)}`);
  // table sorting options
  if (value) {
    if (value.search) queryList.push(`search=${encodeURIComponent(value.search)}`);
    if (value.searchReportsGames && window.location.pathname === '/reports') queryList.push(`search=${encodeURIComponent(value.searchReportsGames)}`);
    if (value.searchReportsPlayers && window.location.pathname === '/reports/players') queryList.push(`search=${encodeURIComponent(value.searchReportsPlayers)}`);
    if (value.page) queryList.push(`page=${value.page}`);
    if (value.itemsPerPage) queryList.push(`size=${value.itemsPerPage}`);
    if (value.sortBy && value.sortBy[0]) {
      queryList.push(`sort=${value.sortDesc[0] ? '' : '-'}${value.sortBy[0]}`);
    }
  }
  // special filter handlers
  if (value && value.date && value.date.length > 0) {
    if (tzoffset) {
      const getDateRange = setDateFromTo(value.date, tzoffset);
      queryList.push(`dateFrom=${getDateRange[0]}&dateTo=${getDateRange[1]}&timezoneOffset=${getDateRange[2]}`);
    } else {
      const getDateRange = setDateFromTo(value.date);
      queryList.push(`dateFrom=${getDateRange[0]}&dateTo=${getDateRange[1]}`);
    }
  }
  if (value && value.roundType) {
    queryList.push(`roundType=${value.roundType}`);
  }
  if (value && value.roundStatus) {
    if (value.roundStatus === 'limited') {
      queryList.push('isWon=true', 'isWonLimited=true');
    } else {
      queryList.push(`isWon=${value.roundStatus}`);
    }
  }
  if (value && value.isWonLimited) {
    queryList.push(`isWonLimited=${value.isWonLimited}`);
  }
  if (value && value.lowerStakeLimit >= 0) {
    queryList.push(`betAmount:gte=${parseInt(value.lowerStakeLimit, 10)}`);
  }
  if (value && value.upperStakeLimit >= 0) {
    queryList.push(`betAmount:lte=${parseInt(value.upperStakeLimit, 10)}`);
  }
  return queryList.join('&');
}
