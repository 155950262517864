import store from '@/store';

export default {
  init(keycloak) {
    const pusher = new Pusher(`${process.env.VUE_APP_PUSHER_KEY}`, {
      cluster: 'eu',
      forceTLS: true,
    });
    if (keycloak) {
      const { sub } = keycloak.tokenParsed;
      const { translations } = store.getters;
      // These events will remain separated for the time being.
      // Future versions may have single bind_global.
      const channel = pusher.subscribe(`NSoft.Casino.Backoffice.User.${sub}`);
      channel.bind('DataExport.Success', (data) => {
        store.dispatch('setPusherMessage', { data, label: data.url.split('/').pop(), download: true });
      });
      channel.bind('DataExport.Error', (data) => {
        store.dispatch('setPusherMessage', { data, label: translations.errorLabel, close: true });
      });
      channel.bind('DataImport.Success', (data) => {
        store.dispatch('setPusherMessage', { data, label: translations.pusherImportSuccessLabel, close: true });
      });
      channel.bind('DataImport.Error', (data) => {
        store.dispatch('setPusherMessage', { data, label: translations.errorLabel, close: true });
      });
      channel.bind('AssetDownload.Success', (data) => {
        store.dispatch('setPusherMessage', { data, label: data.url.split('/').pop(), download: true });
      });
      channel.bind('AssetDownload.Error', (data) => {
        store.dispatch('setPusherMessage', { data, label: translations.errorLabel, close: true });
      });
      channel.bind('AssetUpload.UnpackArchiveSuccess', (data) => {
        store.dispatch('setPusherMessage', { data, label: translations.assetsUnpackArchibeSuccess, close: true });
      });
      channel.bind('AssetUpload.SpritesheetStitchSuccess', (data) => {
        store.dispatch('setPusherMessage', { data, label: translations.assetsSpritesheetStitchSuccess, close: true });
      });
      channel.bind('AssetUpload.Success', (data) => {
        store.dispatch('setPusherMessage', {
          data,
          label: translations.assetsUploadSuccess,
          close: true,
        });
        store.dispatch('clearGameAssetsDetails');
        store.dispatch('loadData', { path: `games/${data.gameId}/assets?size=25&page=1`, name: 'game_assets_details' });
      });
      channel.bind('AssetUpload.Error', (data) => {
        store.dispatch('setPusherMessage', { data, label: translations.assetsUploadError, close: true });
      });
    }
  },
};
