<template>
  <v-navigation-drawer permanent
                       v-model="drawer"
                       app
                       width="100"
                       color="background"
                       class="navigationWrapper"
                       v-if="$route.name != 'unauthorized'">
    <div class="envBar"
         v-if="getEnv">
      {{getEnv}}
    </div>
    <v-list-item to="/">
      <v-row class="my-2">
        <v-list-item-avatar class="mx-auto">
          <v-img src="./../../public/assets/logo.png"></v-img>
        </v-list-item-avatar>
      </v-row>
    </v-list-item>
    <div class="maintenanceBar"
         v-if="bootstrap.globalMaintenanceMode">
         <LocalizedLabel>maintenanceModeActive</LocalizedLabel>
    </div>
    <v-list>
      <v-list-item v-for="item in navigation"
                   :key="item.title"
                   link
                   two-line
                   @click="getNavigationPath(item.link)"
                   :class="getNavigationStyle(item.link)">
        <v-list-item-content class="text-center">
          <v-icon class="mb-1"
                  color="disabled">{{ item.icon }}</v-icon>
          <span class="navigationTitle disabled--text">{{ translations[item.title] }}</span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <SettingsMenu />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SettingsMenu from '@/components/SettingsMenu';
import { authorizedNavigation } from '@/utility';

export default {
  name: 'navbarDesktop',
  components: {
    SettingsMenu,
  },
  data() {
    return {
      drawer: null,
    };
  },
  methods: {
    ...mapActions([
      'toggleDetailsModal',
    ]),
    getNavigationPath(value) {
      if (this.$route.path !== value) {
        this.$router.push(value);
      }
    },
    getNavigationStyle(value) {
      return this.$route.matched[0].path === value ? 'activeLink' : '';
    },
  },
  computed: {
    ...mapGetters([
      'bootstrap',
      'translations',
    ]),
    navigation() {
      return authorizedNavigation();
    },
    getEnv() {
      if (process.env.NODE_ENV === 'development') {
        return 'staging';
      }
      return false;
    },
  },

};
</script>
<style lang="scss" scoped>
  .navigationWrapper {
    .navigationTitle {
      font-size: 11px !important;
      font-weight: 400 !important;
      letter-spacing: 0.4px;
    }
    .activeLink {
      background-color: #545454 !important;
      .navigationTitle,
      i {
        color: var(--v-text-base) !important;
      }
    }
    .theme--light.activeLink {
      background-color: #e5e5e5 !important;
    }
  }
  .envBar {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    height: 12px !important;
    width: 100% !important;
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: var(--v-primary-base) !important;
    color: var(--v-background-base) !important;
  }

  .maintenanceBar {
    padding: 6px 0;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: #FFF;
    background-color: #800020;
  }
</style>
