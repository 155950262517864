import http from './http';

export default {
  async loadData(payload, signal) {
    const response = await http.get(`/${payload.path}`, { signal });
    return response.data;
  },
  async updateData(payload, signal) {
    const response = await http.patch(`/${payload.path}`, payload.data, { signal });
    return response.data;
  },
  async putData(payload, signal) {
    const response = await http.put(`/${payload.path}`, payload.data, { signal });
    return response.data;
  },
  async createData(payload, signal) {
    const response = await http.post(`/${payload.path}`, payload.data, { timeout: payload.timeout, signal });
    return response.data;
  },
  async deleteData(payload, signal) {
    const response = await http.delete(`/${payload.path}`, { signal });
    return response.data;
  },
};
