import types from './mutationTypes';

export default {
  [types.SET_COMPANIES](state, payload) {
    state.companies = payload;
  },
  [types.SET_PARTNERS](state, payload) {
    state.partners = payload;
  },
  [types.SET_GAMES](state, payload) {
    state.games = payload;
  },
  [types.SET_SELECTED_COMPANY](state, payload) {
    state.selectedCompany = payload;
  },
  [types.SET_SELECTED_PARTNER](state, payload) {
    state.selectedPartner = payload;
  },
  [types.SET_DETAILS_MODAL_CONFIG](state, payload) {
    state.detailsModalConfig = payload;
  },
  [types.TOGGLE_DETAILS_MODAL](state, payload) {
    state.toggleDetailsModal = payload;
  },
  [types.SET_COMPANY_GAMES](state, payload) {
    state.companyGames = payload;
  },
  [types.SET_COMPANY_GAME_ROUNDS](state, payload) {
    state.companyGameRounds = payload;
  },
  [types.SET_COMPANY_GAME_DETAILS](state, payload) {
    state.companyGameDetails = payload;
  },
  [types.SET_SELECTED_GAME](state, payload) {
    state.selectedGame = payload;
  },
  [types.SET_SELECTED_ROUND](state, payload) {
    state.selectedRound = payload;
  },
  [types.SET_PLAYERS](state, payload) {
    state.players = payload;
  },
  [types.SET_TAGS](state, payload) {
    state.tags = payload;
  },
  [types.SET_PLAYER_TAGS](state, payload) {
    state.playerTags = payload;
  },
  [types.SET_PLAYER_TAG_DETAILS](state, payload) {
    state.playerTagDetails = payload;
  },
  [types.SET_ASSETS](state, payload) {
    state.assets = payload;
  },
  [types.SET_SELECTED_ASSET_GAME](state, payload) {
    state.selectedAssetGame = payload;
  },
  [types.SET_SELECTED_PROMOTION](state, payload) {
    state.selectedPromotion = payload;
  },
  [types.SET_BOOTSTRAP](state, payload) {
    state.bootstrap = payload;
  },
  [types.SET_REPORTS_GAMES](state, payload) {
    state.reportsGames = payload;
  },
  [types.SET_MOBILE_DETECTION](state, payload) {
    state.isMobile = payload;
  },
  [types.SET_TOOLBAR_TITLE](state, payload) {
    state.toolbarTitle = payload;
  },
  [types.SET_SELECTED_REPORTS_GAME](state, payload) {
    state.selectedReportsGame = payload;
  },
  [types.SET_PROMOTIONS](state, payload) {
    state.promotions = payload;
  },
  [types.SET_PROMOTION_PLAYERS](state, payload) {
    state.promotionPlayers = payload;
  },
  [types.SET_PROMOTION_DETAILS](state, payload) {
    state.promotionDetails = payload;
  },
  [types.SET_REPORTS_PLAYERS](state, payload) {
    state.reportsPlayers = payload;
  },
  [types.SET_PARTNERS_ACCESS_KEYS](state, payload) {
    state.partnerAccessKeys = payload;
  },
  [types.SET_PUSHER_MESSAGE](state, payload) {
    state.pusherMessage = payload;
  },
  [types.SET_ACCEPT_HEADER](state, payload) {
    state.acceptHeader = payload;
  },
  [types.SET_SNACKBAR_NOTIFICATION](state, payload) {
    state.snackbarNotification = payload;
  },
  [types.SET_IMPORT_FILE](state, payload) {
    state.importFile = payload;
  },
  [types.ADD_CANCEL_CONTROLLER](state, payload) {
    state.cancelControllers.push(payload);
  },
  [types.CLEAR_CANCEL_CONTROLLERS](state) {
    state.cancelControllers = [];
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [types.SET_CURRENCY_VALIDATION](state, payload) {
    state.currencyValidation = payload;
  },
  [types.SET_PROMOTION_ERROR_LIST](state, payload) {
    state.promotionErrorList = payload;
  },
  [types.SET_UNIFIED_CURRENCY](state, payload) {
    state.unifiedCurrency = payload;
  },
  [types.SET_EXCLUDE_INTERNAL](state, payload) {
    state.excludeInternal = payload;
  },
  [types.SET_GAME_ASSETS_DETAILS](state, payload) {
    state.gameAssetsDetails.pagination = payload.pagination;
    state.gameAssetsDetails.data = [...state.gameAssetsDetails.data, ...payload.data];
  },
  [types.CLEAR_GAME_ASSETS_DETAILS](state, payload) {
    state.gameAssetsDetails = payload;
  },
  [types.TOGGLE_UPLOAD_ASSETS_DIALOG](state, payload) {
    state.uploadAssetsDialog = payload;
  },
  [types.SET_BOOST_API_REQUEST](state, payload) {
    state.boostUrl = payload;
  },
  [types.SET_TOURNAMENT_DETAILS](state, payload) {
    state.tournamentDetails = payload;
  },
  [types.SET_BOOST_BOOTSTRAP](state, payload) {
    state.boostBootstrap = payload;
  },
  [types.SET_BOOST_COMPANIES](state, payload) {
    state.boostCompanies = payload;
  },
  [types.SET_UNAVAILABLE_BOOST](state) {
    state.boostUnavailable = true;
    state.navigation = state.navigation.filter((nav) => nav.link !== '/boost');
  },
};
