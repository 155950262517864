<template>
  <div>
    <v-menu v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field :value="dateTimeFormatted"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      dense
                      clearable
                      :label="label"
                      outlined
                      :rules="rules"
                      :disabled="disabled"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="clearDateInput"></v-text-field>
      </template>
      <v-card>

        <v-tabs v-model="tab"
                grow
                hide-slider
                height="45">
          <v-tab href="#date">
            <v-icon left>mdi-calendar</v-icon>
            <span v-if="date">{{date}}</span>
            <span v-else
                  class="caption">
              <LocalizedLabel>date</LocalizedLabel>
            </span>
          </v-tab>
          <v-tab href="#time">
            <v-icon left>mdi-clock</v-icon>
            <span v-if="time">{{time}}</span>
            <span v-else
                  class="caption">
              <LocalizedLabel>time</LocalizedLabel>
            </span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab"
                      touchless>
          <v-tab-item value="date">
            <v-date-picker v-model="date"
                           no-title
                           scrollable
                           required
                           :min="minDate"
                           first-day-of-week="1"
                           color="primary"
                           class="dateTimePicker"
                           @input="dateInput">
            </v-date-picker>
          </v-tab-item>
          <v-tab-item value="time">
            <v-time-picker :key="tab"
                           no-title
                           v-model="time"
                           scrollable
                           :disabled="isTimePickerDisabled"
                           :min="minTime"
                           format="24hr"
                           color="primary"
                           class="dateTimePicker"
                           @click:minute="closeMenu">
            </v-time-picker>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  isoToDate,
  isoToDateNoFormat,
  isBeforeDate,
  isBeforeNow,
  isEqualDate,
  isBeforeFullDate,
} from '@/utility';

export default {
  props: [
    'dateSelected',
    'label',
    'type',
    'startDate',
    'endDate',
    'dateStart',
    'rules',
    'disabled',
  ],
  data() {
    return {
      date: null,
      menu: false,
      time: null,
      tab: 'date',
      selectedDateIsoFormat: null,
    };
  },
  methods: {
    clearDateInput() {
      this.date = null;
      this.time = null;
      this.tab = 'date';
      this.$emit('updateDateRange', null, this.type);
    },
    closeMenu() {
      this.tab = 'date';
      this.menu = false;
    },
    dateInput() {
      this.tab = 'time';
      if (this.date && this.time) {
        // clear time picker on date input if selected time was before now
        this.resetTimePicker();
      }
    },
    resetTimePicker() {
      if (this.startDate && isBeforeNow(new Date(`${this.date}T${this.time}`).toISOString())) {
        this.time = null;
      }
      if (this.endDate && isBeforeFullDate(new Date(`${this.date}T${this.time}`), new Date(this.dateStart))) {
        this.time = null;
      }
    },
  },
  computed: {
    dateTimeFormatted: {
      get() {
        if (this.date && this.time) {
          const value = new Date(`${this.date}T${this.time}`).toISOString();
          this.$emit('updateDateRange', value, this.type);
          return isoToDate(value);
        }
        return [];
      },
      set(newValue) {
        const timestamp = isoToDateNoFormat(newValue);
        this.date = timestamp.substring(0, 10);
        this.time = timestamp.substring(11, 16);
      },
    },
    isTimePickerDisabled() {
      return !this.date;
    },
    minDate() {
      if (this.startDate) {
        // disable past dates
        return new Date().toISOString().substring(0, 10);
      }
      if (this.endDate && this.dateStart) {
        return this.dateStart.substring(0, 10);
      }
      return '';
    },
    minTime() {
      if (this.startDate) {
        // disable past time of day if selected date is same as today
        if (!isBeforeDate(this.minDate, this.date)) {
          return isoToDateNoFormat(new Date().toISOString()).substring(11, 16);
        }
      }
      if (this.endDate && this.dateStart) {
        // disable past time of day if selected date is same as startDate
        if (isEqualDate(this.date, this.minDate)) {
          return isoToDateNoFormat(this.dateStart).substring(11, 16);
        }
      }
      return '';
    },
  },
  watch: {
    dateStart(newValue) {
      if (this.type === 'end'
        && (!newValue || isBeforeFullDate(new Date(`${this.date}T${this.time}`), new Date(newValue)))) {
        this.clearDateInput();
      }
    },
  },
  mounted() {
    if (this.dateSelected) {
      this.dateTimeFormatted = this.dateSelected;
    }
  },
};
</script>
