import {
  dropRight,
  find,
  set,
} from 'lodash';

export const selectedColumnsFilter = (allColumns, isMobile) => {
  const selectedColumns = allColumns.filter((column) => column.visible);
  return isMobile ? dropRight(selectedColumns) : selectedColumns;
};

export const localStorageHeaders = (headers) => headers.map(
  (header) => ({ value: header.value, visible: header.visible }),
);

export const updateHeaders = (oldHeaders, newHeaders) => {
  oldHeaders.forEach((header) => {
    const matchingHeader = find(newHeaders, { value: header.value });
    set(header, 'visible', matchingHeader ? matchingHeader.visible : false);
  });
  return oldHeaders;
};

export const setColumnsData = (path, data) => {
  if (localStorage.getItem(path)) {
    return updateHeaders(data, JSON.parse(localStorage.getItem(path)));
  }
  localStorage.setItem(path, JSON.stringify(localStorageHeaders(data)));
  return updateHeaders(data, JSON.parse(localStorage.getItem(path)));
};
