<template>
  <v-app>
    <AppBarMobile v-if="isMobile" />

    <NavbarDesktop v-if="!isMobile" />
    <v-main :class="{mainViewWrapper: isMobile}">

      <router-view></router-view>

      <v-dialog v-model="activator"
                transition="slide-x-reverse-transition"
                content-class="dialogWrapper">
        <v-card width="100%"
                height="100%"
                id="detailsModalId"
                :class="{
                  'pa-8': !isMobile,
                  'overflow-hidden inheritHeight': detailsModalConfig.gamesAssignCompanyLayout}"
                v-touch="{right: () => swipe()}">
          <DetailsModal v-if="activator" />
        </v-card>
      </v-dialog>
    </v-main>
    <NavbarMobile v-if="isMobile" />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEqual } from 'lodash';
import DetailsModal from '@/components/DetailsModal';
import NavbarDesktop from '@/components/NavbarDesktop';
import NavbarMobile from '@/components/NavbarMobile';
import AppBarMobile from '@/components/AppBarMobile';

export default {
  components: {
    DetailsModal,
    NavbarDesktop,
    NavbarMobile,
    AppBarMobile,
  },
  name: 'TemplateDefault',
  data() {
    return {

    };
  },
  methods: {
    ...mapActions([
      'keycloakLogout',
      'toggleDetailsModal',
    ]),
    swipe() {
      this.toggleDetailsModal(false);
    },
  },
  computed: {
    ...mapGetters([
      'detailsModalToggler',
      'detailsModalConfig',
      'isMobile',
    ]),
    activator: {
      get() {
        return this.detailsModalToggler;
      },
      set() {
        this.toggleDetailsModal(false);
      },
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (!isEqual(newValue.name, oldValue.name) && this.detailsModalToggler) {
        this.toggleDetailsModal(false);
      }
    },
  },
  mounted() {
    const theme = localStorage.getItem('theme');
    if (theme === 'false') {
      this.$vuetify.theme.dark = false;
    } else {
      this.$vuetify.theme.dark = true;
    }
  },
};
</script>
<style lang="scss">
@import "./public/assets/scss/main.scss";
.dialogWrapper {
  position: absolute;
  right: 0;
  margin: 0;
  width: 55% !important;
  height: 100% !important;
  max-height: 100% !important;
  overflow: hidden;
  display: flex;
  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
  .v-card {
    overflow: scroll;
  }
}
.mainViewWrapper {
  padding-bottom: 60px !important;
  padding-top: 60px !important;
}
</style>
