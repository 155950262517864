import {
  forEach,
  indexOf,
  map,
  split,
  slice,
} from 'lodash';

export const idToColor = (id, active) => {
  let compile = 0;
  if (active) {
    forEach(id, (value, i) => {
      // eslint-disable-next-line no-bitwise
      compile = id.charCodeAt(i) + ((compile << 7) - compile);
    });
    return `hsl(${compile % 360}, 60%, 40%)`;
  }
  return '#555';
};
export const abbreviateTitle = (name) => {
  if (indexOf(name, ' ') === -1) {
    return name.substring(0, 2);
  }
  return map(slice(split(name, ' '), 0, 3), ((word) => word[0])).join('');
};
