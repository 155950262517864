import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import Touch from 'vuetify/lib/directives/touch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.isMobile)?_c('AppBarMobile'):_vm._e(),(!_vm.isMobile)?_c('NavbarDesktop'):_vm._e(),_c(VMain,{class:{mainViewWrapper: _vm.isMobile}},[_c('router-view'),_c(VDialog,{attrs:{"transition":"slide-x-reverse-transition","content-class":"dialogWrapper"},model:{value:(_vm.activator),callback:function ($$v) {_vm.activator=$$v},expression:"activator"}},[_c(VCard,{directives:[{def: Touch,name:"touch",rawName:"v-touch",value:({right: () => _vm.swipe()}),expression:"{right: () => swipe()}"}],class:{
                'pa-8': !_vm.isMobile,
                'overflow-hidden inheritHeight': _vm.detailsModalConfig.gamesAssignCompanyLayout},attrs:{"width":"100%","height":"100%","id":"detailsModalId"}},[(_vm.activator)?_c('DetailsModal'):_vm._e()],1)],1)],1),(_vm.isMobile)?_c('NavbarMobile'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }