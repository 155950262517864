export default {
  backoffice: (locale) => {
    switch (locale) {
      case 'sr-Latn':
        return import(/* webpackChunkName: "i18n.common.sr-Latn" */'./backoffice.sr-Latn');
      default:
        return import(/* webpackChunkName: "i18n.common" */'./backoffice');
    }
  },
};
