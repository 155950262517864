<template>
  <div class="jackpotHistoryTabWrapper pt-4">
    <v-tabs v-model="tab"
            color="text"
            background-color="transparent">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-for="item in items"
             :key="item"
             :ripple="false">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab"
                  touchless>
      <v-tab-item :transition="false"
                  :reverse-transition="false">
        <div class="pt-6">
          <v-data-table :headers="headers"
                        :items="mappedJackpots"
                        :items-per-page="pagination.size"
                        :server-items-length="pagination.total"
                        :page.sync="pagination.page"
                        :footer-props="combineFooterProps"
                        :loading="loading"
                        :options.sync="options"
                        :sort-by.sync="options.sortBy"
                        :sort-desc.sync="options.sortDesc"
                        class="elevation-0 mt-2"
                        item-key="id">
            <template slot="no-data">
              <LocalizedLabel>noDataAvailableLabel</LocalizedLabel>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { assign, map } from 'lodash';
import { queryParams, isoToShortDate, roundDecimals } from '@/utility';

export default {
  name: 'promotionsJackpotDetails',
  data() {
    const locale = this.$store.getters.translations;
    return {
      tab: null,
      items: [locale.jackpotHistory],
      // No need to save QP for flyout table.
      // If there should be any change, watch out for parent QP.
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      pagination: {},
      jackpotWins: [],
      headers: [
        {
          text: locale.username,
          align: 'start',
          sortable: true,
          value: 'playerUsername',
        }, {
          text: locale.game,
          align: 'start',
          sortable: true,
          value: 'gameDisplayName',
        }, {
          text: locale.config,
          align: 'start',
          sortable: true,
          value: 'gameConfigType',
        }, {
          text: locale.round,
          align: 'start',
          sortable: true,
          value: 'roundId',
        }, {
          text: locale.jackpot,
          align: 'end',
          sortable: true,
          value: 'winAmount',
        }, {
          text: locale.date,
          align: 'end',
          sortable: true,
          value: 'createdAt',
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      'loadData',
    ]),
    async query(value) {
      const params = queryParams(null, value);
      const response = await this.loadData({
        path: `jackpots/${this.$route.query.jackpotDetails}/wins?${params}`,
      });
      if (response) {
        this.jackpotWins = response.data;
        this.pagination = response.pagination;
      }
    },
  },
  computed: {
    ...mapGetters([
      'footerProps',
      'loading',
      'translations',
    ]),
    combineFooterProps() {
      return assign({ disablePagination: this.loading }, this.footerProps);
    },
    mappedJackpots() {
      if (this.jackpotWins) {
        const list = map(this.jackpotWins, (win) => ({
          ...win,
          createdAt: isoToShortDate(win.createdAt) || '-',
          winAmount: roundDecimals(win.winAmount),
        }));
        return list;
      }
      return [];
    },
  },
  watch: {
    options: {
      handler(newValue) {
        this.query(newValue);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .jackpotHistoryTabWrapper {
    .v-tab {
      font-weight: 300;
    }
  }
</style>
