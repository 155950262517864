import { find, isEmpty } from 'lodash';
import { authorizedNavigation } from '../utility';
import store from '../store';

export default function beforeEach(router) {
  router.beforeEach((to, from, next) => {
    if (to.meta.isAuthenticated) {
      if (to.matched[0]?.name !== from.matched[0]?.name) {
        if (to.matched[0]?.name === 'boost') {
          if (!isEmpty(store.state.selectedCompany)) {
            const newCompany = find(store.state.boostCompanies, [
              'displayName',
              store.state.selectedCompany.displayName,
            ]) || store.state.boostCompanies[0];
            store.dispatch('setSelectedCompany', newCompany);
          }
        }
        if (from.matched[0]?.name === 'boost') {
          if (!isEmpty(store.state.selectedCompany)) {
            const newCompany = find(store.state.companies, [
              'displayName',
              store.state.selectedCompany.displayName,
            ]) || store.state.companies[0];
            store.dispatch('setSelectedCompany', newCompany);
          }
        }
      }
      if (to.name === 'boostTournamentDetailsEdit' && !from.name) {
        next({ name: 'boostTournamentsList' });
      }
      if (!store.getters.keycloakState.authenticated) {
        store.dispatch('keycloakLogout');
      } else if (find(authorizedNavigation(), ['link', to.matched[0].path])) {
        store.dispatch('cancelPendingRequests');
        next();
      } else {
        next({ name: 'unauthorized' });
      }
    } else {
      next();
    }
  });
}
