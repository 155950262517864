import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      light: {
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        subtitle: '#BCBCBC',
        background: '#FFFFFF',
        disabled: '#8E8E8E',
        chips: '#CDCDCD',
      },
      dark: {
        primary: '#FFE843',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        text: '#E0E0E0',
        subtitle: '#BCBCBC',
        background: '#1E1E1E',
        disabled: '#8E8E8E',
        chips: '#4B4B4B',
      },
    },
  },
});
