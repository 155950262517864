import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jackpotHistoryTabWrapper pt-4"},[_c(VTabs,{attrs:{"color":"text","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"primary"}}),_vm._l((_vm.items),function(item){return _c(VTab,{key:item,attrs:{"ripple":false}},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c(VDivider),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"transition":false,"reverse-transition":false}},[_c('div',{staticClass:"pt-6"},[_c(VDataTable,{staticClass:"elevation-0 mt-2",attrs:{"headers":_vm.headers,"items":_vm.mappedJackpots,"items-per-page":_vm.pagination.size,"server-items-length":_vm.pagination.total,"page":_vm.pagination.page,"footer-props":_vm.combineFooterProps,"loading":_vm.loading,"options":_vm.options,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"item-key":"id"},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}}},[_c('template',{slot:"no-data"},[_c('LocalizedLabel',[_vm._v("noDataAvailableLabel")])],1)],2)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }