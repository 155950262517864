<template>
  <div>
    <div class="gamesListWrapper py-4">
      <v-tabs v-model="gameType"
              color="text"
              background-color="transparent">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="(type, index) in bootstrap.gameTypes"
               :key="index"
               :ripple="false">
          {{ type }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <div class="gamesListSearch">
        <v-row class="my-2">
          <v-col md="5"
                 :sm="12"
                 class="offset-md-7 pt-4 pb-1 px-3">
            <v-text-field v-model="searchFilter"
                          @keyup.enter="query(true)"
                          outlined
                          dense
                          :label="translations.search"
                          append-icon="mdi-magnify"
                          clearable
                          @click:clear="clearSearchField"
                          hide-details>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-tabs-items v-model="gameType">
        <v-tab-item transition="no-transition"
                    reverse-transition="fade-transition"
                    eager
                    v-for="(type, index) in bootstrap.gameTypes"
                    :key="index">
          <v-row class="overflow-auto gamesList"
                 id="infiniteScroll"
                 ref="infiniteScroll">
            <v-col v-for="game in gamesList"
                   :key="game.id"
                   :sm="6"
                   :md="6"
                   :lg="4"
                   :xs="12"
                   class="gamesItem"
                   outlined>
              <v-card class="mx-auto"
                      outlined>
                <DetailsCard class="gamesItemDetails"
                             :payload="getCardPayload(game)" />
                <div class="d-flex justify-space-between pa-2 py-1 pt-6 switchLabelWrapper">
                  <LocalizedLabel>enabled</LocalizedLabel>
                  <v-switch v-model="game.enabled"
                            hide-details
                            class="ma-0 pa-0">
                  </v-switch>
                </div>
                <div class="d-flex justify-space-between pa-2 py-1 switchLabelWrapper">
                  <LocalizedLabel>visible</LocalizedLabel>
                  <v-switch v-model="game.visible"
                            hide-details
                            class="ma-0 pa-0">
                  </v-switch>
                </div>
                <div class="d-flex justify-space-between pa-2 py-1 switchLabelWrapper"
                     v-if="!isPrizeDrop">
                  <LocalizedLabel>jackpot</LocalizedLabel>
                  <v-switch v-model="game.jackpot"
                            hide-details
                            class="ma-0 pa-0">
                  </v-switch>
                </div>
                <v-card-actions class="justify-center pt-6">
                  <v-btn small
                         width="100"
                         color="primary"
                         class="background--text text-none"
                         :key="actionKey"
                         @click.once="addGame(game)">
                    <LocalizedLabel>add</LocalizedLabel>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { filter, map } from 'lodash';
import {
  abbreviateTitle,
  idToColor,
  queryParams,
  roundDecimals,
} from '@/utility';

import DetailsCard from './DetailsCard';

export default {
  name: 'gamesAssignCompany',
  components: {
    DetailsCard,
  },
  data() {
    return {
      filters: {
        gameType: this.$route.query.gameType,
      },
      searchFilter: '',
      gameType: null,
      gamesList: [],
      actionKey: 0,
      search: '',
      page: 1,
      pagination: {},
    };
  },
  methods: {
    ...mapActions([
      'createData',
      'loadData',
      'setSnackbarNotification',
    ]),
    clearSearchField() {
      this.searchFilter = '';
      this.query();
    },
    async addGame(game) {
      const tenantGamePayload = {
        gameId: game.id,
        active: game.enabled,
        visible: game.visible,
        settings: {
          jackpot: {
            active: game.jackpot,
          },
        },
      };
      const response = await this.createData({
        data: tenantGamePayload,
        path: `tenants/${this.$route.query.company}/games`,
      });
      if (response) {
        this.gamesList = filter(this.gamesList, (item) => item.id !== game.id);
        this.setSnackbarNotification({
          label: this.translations.updateCompanyGamesLabel,
          text: true,
          color: 'success',
        });
      } else {
        this.actionKey += 1;
      }
    },
    getCardPayload(game) {
      return {
        title: game.displayName,
        subtitle: game.theoreticalRtp,
        color: game.thumbUrl === null ? idToColor(game.id, true) : null,
        abbreviateTitle: abbreviateTitle(game.displayName),
        img: game.thumbUrl,
      };
    },
    handleScroll() {
      const element = document.getElementById('infiniteScroll');
      if (element.scrollTop === element.scrollHeight - element.clientHeight
        && this.page < this.pagination.pages) {
        this.page += 1;
        this.query();
      }
    },
    async query(search) {
      const params = queryParams(this.searchFilter, this.filters);
      const page = search ? '' : `&page=${this.page}`;
      const response = await this.loadData({
        path: `tenants/${this.$route.query.company}/games/available?size=15${page}&${params}`,
        loading: false,
      });
      if (response) {
        this.pagination = response.pagination;
        const mapped = map(response.data, (game) => ({
          ...game,
          theoreticalRtp: `${roundDecimals(game.theoreticalRtp)}%`,
          enabled: true,
          visible: true,
          jackpot: true,
        }));
        this.gamesList = search ? [...mapped] : [...this.gamesList, ...mapped];
      }
    },
  },
  computed: {
    ...mapGetters([
      'bootstrap',
      'translations',
    ]),
    isPrizeDrop() {
      return this.bootstrap.gameTypes[this.gameType] === 'PrizeDrop';
    },
  },
  mounted() {
    this.$refs.infiniteScroll.forEach((container) => {
      container.addEventListener('scroll', this.handleScroll);
    });
  },
  watch: {
    gameType(newValue) {
      this.gamesList = [];
      this.page = 1;
      this.filters.gameType = this.bootstrap.gameTypes[newValue];
      this.query();
      this.actionKey += 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.gamesList {
  height: calc(100vh - 260px);
  align-content: baseline;
  padding: 4px;
  &Wrapper {
    ::v-deep {
      .v-card {
        padding: 12px;
      }
      .v-card__actions {
        .v-btn {
          letter-spacing: 0;
          font-size: 14px;
        }
      }
      .v-input--switch {
        .v-input--selection-controls__input {
          margin: 0;
        }
      }
      .v-tabs {
        .v-tab {
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
  }
  .gamesItem {
    padding: 8px;
    &Details {
      padding: 8px;
      width: 100%;
    }
  }
}
.switchLabelWrapper {
  span {
    font-size: 14px;
    opacity: 0.5;
  }
}
</style>
