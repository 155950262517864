<template>
  <div ref="inputWrapper"
       class="selectWrapper">
    <v-autocomplete v-model="selectedItems"
                    :items="mapArray"
                    :item-text="text"
                    :item-value="value"
                    :label="label"
                    :search-input.sync="searchInput"
                    multiple
                    outlined
                    :hide-details="hideDetails"
                    dense
                    :type="inputType"
                    v-touch="{left: () => swipe()}"
                    :rules="rules"
                    :required="required"
                    :return-object="returnObject"
                    :disabled="disabled"
                    :no-data-text="translations.noDataAvailableLabel">
      <template v-slot:item="{item, attrs, on}"
                v-if="customSlots">
        <v-list-item v-on="on"
                     v-bind="attrs"
                     #default="{ active }">
          <v-list-item-action>
            <v-checkbox :ripple="false"
                        :input-value="active"
                        class="customCheckbox"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content v-if="name === 'tenantId'">
            <v-list-item-title v-html="item[text]"></v-list-item-title>
            <v-list-item-subtitle v-if="item.currencies">
              {{getTenantCurrencies(item.currencies)}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-if="name === 'games'">
            <v-list-item-title v-html="item.displayName"></v-list-item-title>
            <v-list-item-subtitle>
              {{item.configType}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip small
                label
                color="primary"
                class="background--text"
                dark
                :style="chipWidth"
                v-if="index === 0">
          {{ getItemText(item)}}
        </v-chip>
        <span v-if="index === 1"
              class="grey--text caption">
          {{itemsSurplus}}
        </span>
      </template>
      <template v-slot:prepend-item>
        <v-list-item ripple
                     @click="toggleSelectAll"
                     v-if="showSelectAll">
          <v-list-item-action>
            <v-icon :color="getIconColor">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <LocalizedLabel>selectAll</LocalizedLabel>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  includes,
  isArray,
  isEqual,
  join,
  map,
} from 'lodash';

export default {
  name: 'filterMultiselect',
  props: [
    'disabled',
    'customSlots',
    'items',
    'text',
    'value',
    'label',
    'querySelected',
    'name',
    'rules',
    'required',
    'returnObject',
    'hideDetails'],
  data() {
    return {
      selectedItems: [],
      searchInput: '',
      chipWidth: '',
      inputType: 'text',
    };
  },
  methods: {
    getItemText(item) {
      if (typeof item === 'object') {
        return item[this.text];
      }
      return item;
    },
    queryToArray() {
      if (isArray(this.querySelected)) {
        this.selectedItems = this.querySelected.map((selection) => (selection));
      } else {
        this.selectedItems.push((this.querySelected));
      }
    },
    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.selectAllItems) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.flattenSelectedItems;
        }
      });
    },
    calculateElementWidth() {
      const inputWidth = this.$refs.inputWrapper.clientWidth;
      if (inputWidth < 250 && this.selectedItems.length > 1) {
        this.chipWidth = `width: ${this.$refs.inputWrapper.clientWidth - 95}px`;
      } else {
        this.chipWidth = '';
      }
    },
    getTenantCurrencies(value) {
      return join(value, ', ');
    },
    swipe() {
      this.inputType = 'text';
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'translations',
    ]),
    flattenSelectedItems() {
      const nameList = ['tenantId', 'game', 'tags', 'tenantGameId', 'status'];
      if (includes(nameList, this.name)) {
        return map(this.mapArray, this.value);
      }
      return this.mapArray;
    },
    getIconColor() {
      return this.selectedItems.length > 0 ? 'primary' : '';
    },
    icon() {
      if (this.selectAllItems) return 'mdi-close-box';
      if (this.selectSomeItems) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    itemsSurplus() {
      return `(+${this.selectedItems.length - 1})`;
    },
    mapArray() {
      if (this.name === 'rtp') {
        const rtpList = this.items.map((item) => String(item.rtp));
        return rtpList;
      }
      return this.items;
    },
    selectAllItems() {
      return this.mapArray && this.selectedItems.length
        ? this.selectedItems.length === this.mapArray.length : null;
    },
    selectSomeItems() {
      return this.selectedItems.length > 0 && !this.selectAllItems;
    },
    showSelectAll() {
      return this.items && this.items.length;
    },
  },
  mounted() {
    if (this.querySelected) {
      this.queryToArray();
    }
    if (isEqual(this.name, 'tenantId') && isEqual(this.items.length, 1)) {
      this.$emit('updateSelectAll', this.name, this.items[0].id, this.label);
    }
    this.inputType = this.isMobile ? 'button' : 'text';
  },
  watch: {
    querySelected() {
      this.queryToArray();
    },
    selectedItems(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.searchInput = '';
        this.$emit('updateSelectAll', this.name, this.selectedItems, this.label);
      }
    },
  },
};
</script>
<style lang="scss">
  .selectWrapper {
    width: 100%;
    .v-autocomplete.v-select.v-input--is-focused input {
      min-width: 0px;
    }
    .v-autocomplete.v-select.v-select__selections {
      white-space: nowrap;
    }
    .v-select__selections {
      flex-wrap: nowrap !important;
      max-height: 40px;
    }
  }
  .customCheckbox {
    pointer-events: none !important;
  }
</style>
