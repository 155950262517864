import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAppBar,{attrs:{"height":"60","fixed":"","elevation":"0","color":"background"}},[(_vm.toolbarTitle.active)?_c(VBtn,{staticClass:"text--text",attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.getLocation()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1):_vm._e(),_c(VToolbarTitle,{staticClass:"title text--text px-0 font-weight-light"},[_vm._v(" "+_vm._s(_vm.toolbarTitle.label || 'Backoffice')+" ")]),_c(VSpacer),(!_vm.toolbarTitle.active)?_c('SettingsMenu'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }