import Vue from 'vue';
import Keycloak from 'keycloak-js';
import { pusher } from '@/plugins';
import App from './App';
import router from './router';
import store from './store';
import LocalizedLabel from './components/LocalizedLabel';
import i18n from './i18n';
import vuetify from './plugins/vuetify';

Vue.use(LocalizedLabel);

Vue.config.productionTip = false;

const keycloak = Keycloak(store.state.config, store.state.initOptions);
(async () => {
  const translations = (await i18n.backoffice()).default;
  store.state.translations = translations;

  keycloak.init(store.state.initOptions).then((auth) => {
    store.state.keycloak = keycloak;
    if (!auth) {
      window.location.reload();
    } else {
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
      pusher.init(keycloak);
      store.dispatch('keycloakRefreshToken');
    }
  });
})();

/* Standard keycloak APIs init method call which returns a promise.
On success, Render Vue application within that success method to prevent
exposing any Vue resource before authentication is completed. */

/* Avoid saving the user token and authenticated inside localStorage since
you can always retrieve that data from a keycloak instance
(which is safer and always guaranteed to be in-sync with the actual
Keycloak user-session). */

/* If keycloak.init is placed inside vuex actions the app will load twice,
first to initialize vuex then to check for credetials. This should be revisited but for now
I will leave it like this. */
