import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('div',{staticStyle:{"height":"100%"},attrs:{"id":"app"}},[(_vm.loaded)?_c('TemplateDefault'):_vm._e(),_c(VSnackbar,{attrs:{"timeout":_vm.timeout,"text":_vm.text,"color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [(_vm.download)?_c(VBtn,_vm._b({staticClass:"text-none",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.getExportedData(_vm.pusherMessage.data.url)}}},'v-btn',attrs,false),[_c('LocalizedLabel',[_vm._v("download")])],1):_vm._e(),(_vm.promotionError)?_c(VBtn,_vm._b({staticClass:"text-none",attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.getPromotionErrorList()}}},'v-btn',attrs,false),[_c('LocalizedLabel',[_vm._v("showList")])],1):_vm._e(),(_vm.isSnackbarPersistent)?_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),(_vm.isSnackbarPersistent)?_c(VBtn,{staticClass:"mx-2",attrs:{"icon":""},on:{"click":function($event){_vm.snackbar = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{staticClass:"mx-6 text-center",class:_vm.getLabelPosition},[_vm._v(" "+_vm._s(_vm.snackbarLabel)+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }