<template>
  <div>
    <v-divider class="my-4"></v-divider>
  </div>
</template>

<script>

export default {
  name: 'companiesPartnerDetails',
  data() {
    return {
    };
  },
};
</script>
