<template>
  <div>
    <v-menu v-model="menu"
            :close-on-content-click="false"
            nudge-top="20"
            offset-y
            top
            v-if="!isMobile">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-avatar size="44"
                            class="mx-auto menuWrapper"
                            v-bind="attrs"
                            v-on="on"
                            :color="profileDetails.color">
          <span class="white--text text-uppercase menuAvatar">
            {{profileDetails.abbreviateTitle}}</span>
        </v-list-item-avatar>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="44"
                                :color="profileDetails.color">
              <span class="white--text text-uppercase menuAvatar">
                {{profileDetails.abbreviateTitle}}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{keycloakState.tokenParsed.given_name}}
                {{keycloakState.tokenParsed.family_name}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{keycloakState.tokenParsed.email}}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn @click="keycloakLogout()"
                     class="text--text text-none"
                     color="disabled"
                     small>
                <LocalizedLabel>signOut</LocalizedLabel>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-switch @click="toggleTheme"
                  v-model="$vuetify.theme.dark"
                  :label="translations.changeTheme"
                  class="pl-4 pt-4 switchLabel">
        </v-switch>
        <v-switch @click="toggleUnifiedCurrency"
                  :disabled="loading"
                  v-model="unifiedCurrency"
                  hide-details
                  class="pl-4 pb-4 my-2 switchLabel">
          <template v-slot:label>
            <LocalizedLabel>unifiedCurrency</LocalizedLabel>
            <v-tooltip transition="slide-x-transition"
                       right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dark
                        small
                        color="disabled"
                        class="pl-2 mt-n1"
                        v-bind="attrs"
                        v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span v-html="translations.unifiedCurrencyInfoLabel"></span>
            </v-tooltip>
          </template>
        </v-switch>
        <v-switch @click="toggleExcludeInternal"
                  v-model="excludeInternal"
                  hide-details
                  :label="translations.excludeInternalCompanies"
                  class="pl-4 pb-4 my-2 switchLabel">
        </v-switch>
        <v-switch @click="globalMaintenanceDialog = true"
                  :disabled="loading"
                  v-model="globalMaintenanceMode"
                  hide-details
                  :label="translations.globalMaintenanceMode"
                  class="pl-4 pb-4 my-2 switchLabel">
        </v-switch>
        <v-card-text class="pt-1 subtitle-1">
          <LocalizedLabel>resetToDefault</LocalizedLabel>
          <v-btn outlined
                 small
                 text
                 class="ml-7 text-none text--text body-2"
                 @click="clearLocalStorage">
            <LocalizedLabel>reset</LocalizedLabel>
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog v-model="menu"
              transition="slide-x-reverse-transition"
              content-class="menuDialogWrapper"
              v-if="isMobile">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="36"
                  v-bind="attrs"
                  v-on="on"
                  :color="profileDetails.color">
          <span class="white--text text-uppercase menuAvatar subtitle-1">
            {{profileDetails.abbreviateTitle}}</span>
        </v-avatar>
      </template>
      <v-card class="mx-auto"
              max-width="434"
              v-touch="{right: () => swipe()}"
              tile>
        <v-img :aspect-ratio="16/9"
               :gradient="gradient">
          <v-layout class="pl-8 pb-4"
                    column
                    fill-height>
            <v-spacer></v-spacer>
            <v-avatar size="65"
                      rounded
                      :color="profileDetails.color"
                      class="mb-2">
              <span class="white--text text-uppercase menuAvatar text-h5">
                {{profileDetails.abbreviateTitle}}</span>
            </v-avatar>
            <div class="title font-weight-regular">
              {{keycloakState.tokenParsed.given_name}}
              {{keycloakState.tokenParsed.family_name}}
            </div>
            <div class="subtitle-1 font-weight-thin">
              {{keycloakState.tokenParsed.email}}
            </div>
          </v-layout>
        </v-img>
        <v-switch @click="toggleTheme"
                  v-model="$vuetify.theme.dark"
                  :label="translations.changeTheme"
                  class="pa-0 ma-0 pl-8 pt-8 switchLabel">
        </v-switch>
        <v-switch @click="toggleUnifiedCurrency"
                  :disabled="loading"
                  v-model="unifiedCurrency"
                  class="pa-0 ma-0 pl-8 switchLabel">
          <template v-slot:label>
            <LocalizedLabel>unifiedCurrency</LocalizedLabel>
            <v-tooltip transition="slide-x-transition"
                       bottom
                       max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon dark
                        small
                        color="disabled"
                        class="pl-2 mt-n1"
                        v-bind="attrs"
                        v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span v-html="translations.unifiedCurrencyInfoLabel"></span>
            </v-tooltip>
          </template>
        </v-switch>
        <v-switch @click="toggleExcludeInternal"
                  v-model="excludeInternal"
                  :label="translations.excludeInternalCompanies"
                  class="pa-0 ma-0 pl-8 switchLabel">
        </v-switch>
        <v-card-text class="pt-1 ml-2 subtitle-1">
          <LocalizedLabel>resetToDefault</LocalizedLabel>
          <v-btn outlined
                 small
                 text
                 class="ml-7 text-none text--text body-2"
                 @click="clearLocalStorage">
            <LocalizedLabel>reset</LocalizedLabel>
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn class="text--text logoutButton text-none"
                 text
                 @click="keycloakLogout()">
            <LocalizedLabel>signOut</LocalizedLabel>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="globalMaintenanceDialog"
              max-width="450">
      <v-card>
        <v-card-title class="title mb-4">
          <LocalizedLabel>globalMaintenanceMode</LocalizedLabel>
        </v-card-title>
        <v-card-text>
          <span v-html="maintenanceModeDialogMessage"></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary"
                 text
                 @click="closeGlobalMaintenanceDialog">
            <LocalizedLabel>cancel</LocalizedLabel>
          </v-btn>

          <v-btn color="primary"
                 text
                 @click="toggleGlobalMaintenanceMode">
                 {{ maintenanceModeDialogActionLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { template } from 'lodash';
import {
  abbreviateTitle,
  idToColor,
} from '@/utility';

export default {
  name: 'settingsMenu',
  data() {
    return {
      menu: false,
      unifiedCurrency: JSON.parse(localStorage.getItem('unifiedCurrency')),
      excludeInternal: JSON.parse(localStorage.getItem('excludeInternal')),
      globalMaintenanceMode: false,
      globalMaintenanceDialog: false,
    };
  },
  methods: {
    ...mapActions([
      'getBootstrap',
      'keycloakLogout',
      'setExcludeInternal',
      'setUnifiedCurrency',
      'updateData',
    ]),
    toggleTheme() {
      localStorage.setItem('theme', this.$vuetify.theme.dark);
    },
    toggleUnifiedCurrency() {
      this.setUnifiedCurrency(this.unifiedCurrency);
      localStorage.setItem('unifiedCurrency', this.unifiedCurrency);
    },
    toggleExcludeInternal() {
      this.setExcludeInternal(this.excludeInternal);
      localStorage.setItem('excludeInternal', this.excludeInternal);
    },
    closeGlobalMaintenanceDialog() {
      this.globalMaintenanceMode = !this.globalMaintenanceMode;
      this.globalMaintenanceDialog = false;
    },
    async toggleGlobalMaintenanceMode() {
      this.globalMaintenanceDialog = false;

      const response = await this.updateData({
        path: 'backoffice/maintenance',
        data: {
          maintenance: {
            isActive: this.globalMaintenanceMode,
          },
        },
        notification: 'globalMaintenanceNotification',
      });

      if (response?.success) {
        await this.getBootstrap();
      } else {
        this.globalMaintenanceMode = !this.globalMaintenanceMode;
      }
    },
    swipe() {
      this.menu = false;
    },
    clearLocalStorage() {
      localStorage.clear();
      window.location.reload();
    },
  },
  computed: {
    ...mapGetters([
      'bootstrap',
      'isMobile',
      'isUnifiedCurrency',
      'keycloakState',
      'loading',
      'translations',
    ]),
    profileDetails() {
      const info = this.keycloakState.tokenParsed;
      return {
        color: idToColor(info.email, true),
        abbreviateTitle: abbreviateTitle(`${info.given_name} ${info.family_name}`),
      };
    },
    gradient() {
      if (this.$vuetify.theme.dark) {
        return 'to top right, #303030, #5f5a37';
      }
      return 'to top right, #fff, var(--v-primary-base)';
    },
    maintenanceModeDialogActionLabel() {
      return this.globalMaintenanceMode ? this.translations.turnOn : this.translations.turnOff;
    },
    maintenanceModeDialogMessage() {
      return template(this.translations.maintenanceModeDialogMessage)({ actionLabel: this.maintenanceModeDialogActionLabel });
    },
  },
  mounted() {
    this.globalMaintenanceMode = this.bootstrap.globalMaintenanceMode;
  },
};
</script>

<style lang="scss">
.menuWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;
}
.menuAvatar {
  width: 100%;
  text-align: center;
}
.menuDialogWrapper {
  position: absolute;
  right: 0;
  margin: 0;
  width: 85% !important;
  height: 100% !important;
  max-height: 100% !important;
  overflow: hidden;
  display: flex;
}
.logoutButton {
  position: absolute;
  bottom: 16px;
  left: 16px;
}
.switchLabel label {
  padding-left: 15px;
}
</style>
