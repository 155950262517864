<template>
  <div class="d-flex flex-no-wrap">
    <v-avatar class="cardThumbnail ma-0"
              size="64"
              :color="payload.color">
      <span v-if="payload.color"
            class="white--text headline text-uppercase">{{payload.abbreviateTitle}}</span>
      <v-img v-else
             :src="payload.img"
             alt></v-img>
    </v-avatar>
    <div class="ml-4 oneliner">
      <span class="subtitle-1 pa-0 py-2">{{ payload.title }}</span>
      <v-icon class="error--text ml-3"
              v-if="payload.maintenanceActive">mdi-wrench-clock</v-icon>
      <v-card-subtitle class="pa-0 pt-2">{{ payload.subtitle }}</v-card-subtitle>
    </div>
  </div>
</template>

<script>
import { upperCase } from 'lodash';

export default {
  name: 'detailsCard',
  props: {
    payload: {
      type: Object,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    formatLetters() {
      return upperCase(this.payload.title.substring(0, 2));
    },
  },
};
</script>

<style lang="scss">
.cardThumbnail.v-avatar {
  border-radius: 4px !important;
}
.oneliner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:active {
    overflow: visible;
    white-space: normal;
    height: auto;
  }
}
</style>
