<template>
  <div @drop.prevent="onDrop"
       @dragover.prevent>
    <v-row class="d-flex align-center justify-end mb-3">
      <v-col cols="4">
        <v-text-field v-model="search"
                      outlined
                      dense
                      :label="translations.search"
                      append-icon="mdi-magnify"
                      hide-details>
        </v-text-field>
      </v-col>
      <v-menu bottom
              left
              :nudge-width="200"
              transition="scroll-x-reverse-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon color="subtitle">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense
                class="pa-0">
          <v-list-item @click="downloadAssetsPerGame"
                       class="pa-2 pl-4">
            <v-list-item-icon>
              <v-icon class="subtitle--text"> mdi-pencil </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title class="subtitle--text">
                <LocalizedLabel>downloadAll</LocalizedLabel>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toggleUploadAssetsDialog(true)"
                       class="pa-2 pl-4">
            <v-list-item-icon>
              <v-icon class="subtitle--text"> mdi-upload </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title class="subtitle--text">
                <LocalizedLabel>importZip</LocalizedLabel>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-data-table :headers="headers"
                  id="assetsTableId"
                  :items="mappedAssets"
                  :height="modalHeight"
                  fixed-header
                  :loading="loading"
                  :no-data-text="translations.noAssetsGame"
                  :items-per-page="-1"
                  :search="search"
                  :hide-default-footer="true"
                  @click:row="showAsset">
      <template v-slot:[`item.status`]="{ item }">
        <span :class="assetStatusColor(item.status)">{{ item.status }}</span>
      </template>
    </v-data-table>
    <v-dialog v-model="assetsUploadDialog"
              @click:outside="toggleUploadAssetsDialog(false)"
              max-width="450px">
      <v-card>
        <v-card-title class="title mb-4">
          <LocalizedLabel>importAssets</LocalizedLabel>
        </v-card-title>
        <v-card-text>
          <div @drop.prevent="onDrop"
               @dragover.prevent>
            <v-file-input v-model="file"
                          hide-details
                          :label="translations.importZipLabel"
                          accept="application/zip"
                          prepend-inner-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                          prepend-icon=""></v-file-input>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 text
                 @click="toggleUploadAssetsDialog(false)">
            <LocalizedLabel>cancel</LocalizedLabel>
          </v-btn>
          <v-btn color="primary"
                 :disabled="!file"
                 text
                 @click="uploadFile">
            <LocalizedLabel>send</LocalizedLabel>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { map } from 'lodash';

export default {
  name: 'AssetsUpload',
  data() {
    const locale = this.$store.getters.translations;
    return {
      search: '',
      file: null,
      page: 1,
      headers: [
        {
          text: '#',
          value: 'index',
          align: 'start',
          sortable: true,
        },
        {
          text: locale.name,
          value: 'name',
          align: 'start',
          sortable: true,
        },
        {
          text: locale.type,
          value: 'type',
          align: 'start',
          sortable: true,
        },
        {
          text: locale.status,
          align: 'start',
          value: 'status',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      'clearGameAssetsDetails',
      'createData',
      'loadData',
      'setGameAssetsDetails',
      'setPusherMessage',
      'setSnackbarNotification',
      'toggleUploadAssetsDialog',
    ]),
    assetStatusColor(status) {
      return status === 'Uploaded' ? 'success--text' : 'error--text';
    },
    onDrop(value) {
      const error = this.assetsUploadErrorHandler(value.dataTransfer);
      if (error) {
        this.setSnackbarNotification({
          label: this.translations[error],
          text: true,
          color: 'error',
        });
      } else {
        [this.file] = value.dataTransfer.files;
      }
    },
    assetsUploadErrorHandler(data) {
      if (data.files.length > 1) {
        return 'noMultipleFilesError';
      }
      if (data.files[0] && data.files[0].type !== 'application/zip') {
        return 'unsupportedTypeErrorLabel';
      }
      return false;
    },
    async downloadAssetsPerGame() {
      await this.createData({
        path: `games/${this.selectedAssetGame.gameId}/assets/archive/download`,
        notification: 'exportStartedLabel',
        color: true,
      });
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      this.createData({
        data: formData,
        path: `games/${this.selectedAssetGame.gameId}/assets/archive/upload`,
        timeout: 60000,
        notification: 'assetsUploadStartedLabel',
        color: true,
      });
      this.setPusherMessage({
        label: this.translations.assetsSent,
        close: true,
      });
      this.file = null;
      this.toggleUploadAssetsDialog(false);
    },
    handleScroll() {
      const element = document.querySelector('#assetsTableId .v-data-table__wrapper');
      if (this.page > this.gameAssetsDetails.pagination.page) this.page = 1;
      if (element.scrollTop === element.scrollHeight - element.clientHeight
        && this.page < this.gameAssetsDetails.pagination.pages) {
        this.page += 1;
        this.getAssetsDetails();
      }
    },
    async getAssetsDetails() {
      await this.loadData({
        path: `games/${this.selectedAssetGame.gameId}/assets?size=25&page=${this.page}`,
        name: 'game_assets_details',
      });
    },
    showAsset(asset) {
      if (asset.path) window.open(asset.path, '_blank');
    },
  },
  computed: {
    ...mapGetters([
      'assetsUploadDialog',
      'gameAssetsDetails',
      'loading',
      'selectedAssetGame',
      'translations',
    ]),
    mappedAssets() {
      const list = map(this.gameAssetsDetails.data, (asset, index) => ({
        ...asset,
        name: asset.name || '-',
        type: asset.mimeType || '-',
        status: !asset.uploaded && asset.required
          ? this.translations.missing
          : this.translations.uploaded,
        index: `${index + 1}.`,
      }));
      return list;
    },
    modalHeight() {
      return `${window.innerHeight - 270}px`;
    },
  },
  async mounted() {
    const element = document.querySelector('#assetsTableId .v-data-table__wrapper');
    element.addEventListener('scroll', this.handleScroll);
    await this.getAssetsDetails();
  },
  beforeDestroy() {
    this.clearGameAssetsDetails();
  },
  watch: {
    file(value) {
      if (value) {
        this.toggleUploadAssetsDialog(true);
      } else {
        this.toggleUploadAssetsDialog(false);
      }
    },
  },
};
</script>
