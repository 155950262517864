import {
  isEqual,
  omit,
  range,
} from 'lodash';

export const convertPrizesToSingleFormat = (prizes) => prizes.flatMap((prize) => {
  if (prize.fromPosition && prize.toPosition && prize.fromPosition < 1001) {
    const startPosition = Number(prize.fromPosition);
    const endPosition = Number(prize.toPosition) < 1001 ? Number(prize.toPosition) + 1 : 1001;
    const positions = range(startPosition, endPosition);
    let details;

    switch (prize.type) {
      case 'NSoftBalancePayout':
        details = { amount: Number(prize.amount) };
        break;
      case 'NSoftCasinoFreeRounds':
        details = {
          prizeCountPerPlayer: Number(prize.amount),
          amountPerPlayer: Number(prize.stakePerSpin),
        };
        break;
      case 'Custom':
        details = { description: prize.description };
        break;
      default:
        details = {};
    }

    return positions.map((position) => ({ position, type: prize.type, details }));
  }
  return [];
});

export const convertPrizesToRangeFormat = (prizes) => {
  const slicedPrizes = prizes.slice(0, 1000);

  return slicedPrizes.reduce((result, prize, index) => {
    if (index === 0 || !isEqual(omit(prize, 'position'), omit(prizes[index - 1], 'position'))) {
      const rangePrize = {
        fromPosition: prize.position,
        toPosition: prize.position,
        type: prize.type,
      };

      switch (prize.type) {
        case 'NSoftBalancePayout':
          rangePrize.amount = prize.details.amount;
          break;
        case 'NSoftCasinoFreeRounds':
          rangePrize.amount = prize.details.prizeCountPerPlayer;
          rangePrize.stakePerSpin = prize.details.amountPerPlayer;
          break;
        case 'Custom':
          rangePrize.description = prize.details.description;
          break;
        default:
          break;
      }

      result.push(rangePrize);
    } else {
      // eslint-disable-next-line no-param-reassign
      result[result.length - 1].toPosition = prize.position;
    }
    return result;
  }, []);
};
